import { Link } from "gatsby"
import React, { Component } from "react"
import styled from "styled-components"
import Card from "../shared/card"

const Login = styled.div`
  height: 90vh;
  overflow: hidden;
`
class LoginContent extends Component {
  state = { error: false }
  formSubmit = e => {
    e.preventDefault()
    this.setState({ error: true })
  }
  render() {
    return (
      <Login className="flex items-center justify-center p-6 my-10 md:my-0 overflow-hidden">
        <Card className="max-w-full bg-white rounded overflow-hidden shadow-lg">
          <div className="px-8 py-6">
            <form onSubmit={this.formSubmit}>
              <div className="font-semibold text-center text-gray-800 text-2xl mb-3">
                Account Login
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="font-semibold text-md text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="px-4 py-2 w-full focus:outline-none bg-gray-200 rounded hover:border-blue-400 placeholder-gray-700 mt-2"
                  placeholder="janedoe@example.com"
                  required
                />
              </div>
              <div className="my-5">
                <label
                  htmlFor="password"
                  className="font-semibold text-md text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  className="px-4 py-2 w-full focus:outline-none bg-gray-200 rounded hover:border-blue-400 placeholder-gray-700 mt-2"
                  placeholder="**********"
                  required
                />
              </div>
              {this.state.error === true ? (
                <div
                  className="text-xs mt-3 bg-red-100 border-t-2 border-red-500 rounded-b text-red-900 px-3 py-1 shadow-md"
                  role="alert"
                >
                  We're having trouble signing you in. Please contact support.
                </div>
              ) : (
                <></>
              )}
              <div className="flex justify-center">
                <button
                  className="bg-black hover:bg-gray-800 active:bg-gray-900 hover:text-gray-100 focus:outline-none text-gray-100 font-semibold text-lg py-2 px-4 rounded"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div className="mt-3 text-sm text-center font-semibold text-gray-700">
                Don't have an account with Archerhub?{" "}
                <br className="lg:hidden" />
                <Link className="text-blue-500" to="/signup-shipper">
                  Sign up now!
                </Link>
              </div>
              <div className="text-xs text-center font-semibold text-gray-700">
                Having any issues? <br /> Call us at{" "}
                <a className="text-blue-500" href="tel:303-542-7110">
                  (303) 542 - 7110
                </a>{" "}
                or email{" "}
                <a
                  className="text-blue-500"
                  href="mailto:info@archerhub.com?Subject=Enquiry"
                  target="_top"
                >
                  support@archerhub.com
                </a>
              </div>
            </form>
          </div>
        </Card>
      </Login>
    )
  }
}
export default LoginContent
