import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Parent = styled.div`
  margin-top: ${({ mt }) => mt};
  margin-left: ${({ ml }) => ml};
  margin-bottom: ${({ mb }) => mb};
  margin-right: ${({ mr }) => mr};
`

const Card = ({ className, children, marginTop, marginLeft, marginBottom, marginRight }) => (
  <Parent className={className} mt={marginTop} ml={marginLeft} mb={marginBottom} mr={marginRight}>
    {children}
  </Parent>
)

Card.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.string,
  marginLeft: PropTypes.string,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
}

Card.defaultProps = {
  className: null,
  children: null,
  marginTop: null,
  marginLeft: null,
  marginBottom: null,
  marginRight: null,
}

export default Card
