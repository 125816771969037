import React, { Component } from "react"
import Layout from "../components/layout"
import LoginContent from "../components/login/login-content"
import SEO from "../components/seo"
class Login extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Login" />
        <LoginContent></LoginContent>
      </Layout>
    )
  }
}
export default Login
